export default {
  SET_QR_CODE_LIST(state, payload) {
    state.qrCodeList = payload;
  },
  SET_QR_CODE_COUNT(state, payload) {
    state.qrCodeCount = payload;
  },
  SET_QR_LIST(state, qrList) {
    state.qrList = qrList;
  },
  SET_QR_COUNT(state, qrCount) {
    state.qrCount = qrCount;
  },
  SET_QR_DETAIL(state, qrDetail) {
    state.qrDetail = qrDetail;
  },
  SET_QR_SHOPS_LIST(state, qrShops) {
    state.qrShops = qrShops;
  }
  
}