import http from '@/config/api';

export default {
  async getQrCodeList({ commit }, payload) {
    return await http.get(`event/${payload.subdomain}/shops/qrcodes`, { params : payload }).then((response) => {
      if (response) {
        commit('SET_QR_CODE_LIST', response.data.list.filter((el) => el.value));
        commit('SET_QR_CODE_COUNT', response.data?.count);
      }
    })
  },
  async getQrList({ commit }, payload) {
    return await http.get(`event/${payload.subdomain}/shops/qrcode/index`, { params : payload }).then((response) => {
      if (response) {
        commit('SET_QR_LIST', response.data?.list);
        commit('SET_QR_COUNT', response.data?.count);
      }
    })
  },
  async getQRDetail({ commit }, params) {
    return await http.get(`/event/${params.subdomain}/shops/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_QR_DETAIL', response?.data);
        return response?.data;
      }
    });
  },
  async createQr(_, payload) {
    return await http.post(`/event/${payload.subdomain}/shops/qrcode`, payload).then(response => response?.data?.id);
  },
  async updateQr(_, payload) {
    return await http.post(`/event/${payload.subdomain}/shops/qrcode/${payload.id}`, payload).then(response => response?.data?.id);
  },
  async deleteQr(_, payload) {
    return await http.delete(`event/${payload.subdomain}/shops/qrcode`, {
      data: payload?.data,
    });
  },
  async getQrShopsList({ commit }, params) {
    await http.get(`/event/${params.subdomain}/shops/pulldown`, { params }).then(response => {
      if (response) {
        commit('SET_QR_SHOPS_LIST', response?.data);
      }
    });
  },
}